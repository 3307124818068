import React, { useEffect, useState } from "react";
import { streamCrewStandby } from "../../firebase";
import { currentSlideCrew3 } from "../../hooks/summaryLimopsCarrousel";
import GenericTitle from "../GenericTitle/GenericTitle";
import Loader from "../Loader/Loader";
import "./CrewStandby.css";
import CrewStandbyGroup from "./CrewStandbyGroup/CrewStandbyGroup";

// Variables globales para controlar el estado de carga y los intervalos
let isLoading = true;
let actualCfSlide = 1;
let crewStandByList = [];
let crewStandByGroupList = [];
let intervalId;
let lastUpdate;

function CrewStandby(props) {
  // Estado para manejar la lista de standby y si se está mostrando la vista de Brasil
  const [state, setState] = useState({ currentOp: "", crewStandbyList: [] });
  const [isBrasil, setIsBrasil] = useState(() => {
    // Cargar configuración inicial desde localStorage
    const storedConfig = JSON.parse(localStorage.getItem("crewStandbyConfig"));
    return storedConfig?.orderView?.checked === "BRA";
  });

  // Estado para los filtros seleccionados
  const [selectedFilters] = useState(() => {
    const storedConfig = JSON.parse(localStorage.getItem("crewStandbyConfig"));
    return storedConfig?.selectedFilters || [];
  });

  useEffect(() => {
    // Suscribirse al stream de datos de Crew Standby
    return streamCrewStandby({
      next: (snapshot) => {
        isLoading = false;
        const { fltSummaryShift, fltSummaryShiftBra } = snapshot;
        if (fltSummaryShift && fltSummaryShiftBra) {
          // Procesar datos según la vista seleccionada (Brasil o no)
          const updatedDataBra = processCrewData(
            fltSummaryShiftBra.data,
            isBrasil
          );
          console.log("Data original Bra:", updatedDataBra);
          //Se mapean los datos directamente sin aplicar transformaciones adicionales en SSC
          const updatedDataShift = fltSummaryShift.data.map((item) => ({
            ...item,
          }));
          console.log("Data original SSC:", updatedDataShift);

          // Aplicar filtros a los datos procesados
          const filteredData = isBrasil ? updatedDataBra : updatedDataShift;
          const finalData = applyFilters(
            filteredData,
            selectedFilters,
            isBrasil
          );

          console.log("data de filtered:", finalData);

          // Actualizar el estado con los datos finales filtrados
          setState({ crewStandbyList: finalData });
          lastUpdate = new Date();
          moveSlide(actualCfSlide);
        } else {
          lastUpdate = new Date();
          setState({ crewStandbyList: [] });
        }
      },
      error: (e) => {
        console.error(e);
        isLoading = false;
      },
    });
  }, [isBrasil, selectedFilters]);

  // Función para aplicar filtros a los datos
  const applyFilters = (data, filters, isBrasil) => {
    if (!filters.length) return data; // Si no hay filtros, devolver todos los datos

    return data.filter((item) => {
      if (isBrasil) {
        // Para Brasil, usa baseCode
        return filters.includes(item.subFleetCode + item.baseCode);
      } else {
        // Para otros, usa companyCode
        const companyCode = item.companyCode
          ? item.companyCode.split("/")[0]
          : "";
        return filters.includes(item.subFleetCode + companyCode);
      }
    });
  };

  // Función para procesar los datos de Crew Standby
  const processCrewData = (data, isBrasil) => {
    if (!Array.isArray(data)) {
      throw new Error("Data debe ser un array");
    }
    const isBrasilProced = !!isBrasil;
    if (typeof isBrasilProced !== "boolean") {
      throw new Error("isBrasil debe ser un booleano");
    }

    const timeRanges = [
      { start: "00:00", end: "03:00" },
      { start: "03:00", end: "06:00" },
      { start: "06:00", end: "09:00" },
      { start: "09:00", end: "12:00" },
      { start: "12:00", end: "15:00" },
      { start: "15:00", end: "18:00" },
      { start: "18:00", end: "21:00" },
      { start: "21:00", end: "00:00" },
    ];

    const result = [];

    timeRanges.forEach((range, index) => {
      const filteredData = data.filter((item) => {
        return item.workShiftType === index.toString();
      });

      filteredData.forEach((item) => {
        const [assignedMando, neededMando] = item.mando.split("/").map(Number);
        const [assignedCabina, neededCabina] = item.cabina
          .split("/")
          .map(Number);

        const mandoValue =
          (isNaN(assignedMando) ? "0" : assignedMando) +
          "/" +
          (isNaN(neededMando) ? "0" : neededMando);
        const cabinaValue =
          (isNaN(assignedCabina) ? "0" : assignedCabina) +
          "/" +
          (isNaN(neededCabina) ? "0" : neededCabina);

        result.push({
          timeRange: `${range.start}-${range.end}`,
          subFleetCode: item.subFleetCode,
          baseCode: item.baseCode,
          CABINA: cabinaValue,
          MANDO: mandoValue,
        });
      });
    });

    return result;
  };

  // Actualizar el estado de isBrasil cuando cambian las props
  useEffect(() => {
    setIsBrasil(
      props.crewStandbyCheckbox.crewStandbyConfig.orderView.checked === "BRA"
    );
  }, [props.crewStandbyCheckbox]);

  // Configurar el intervalo para el cambio de diapositivas
  useEffect(() => {
    currentSlideCrew3(actualCfSlide);
    if (!intervalId) {
      intervalId = setInterval(() => moveSlide(actualCfSlide + 1), 25000);
    }
    return () => cleanUpInterval();
  }, [props]);

  // Limpiar el intervalo cuando el componente se desmonta
  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  };

  // Función para mover la diapositiva
  const moveSlide = (slideNum) => {
    if (slideNum > crewStandByGroupList.length) {
      slideNum = 1;
    }
    actualCfSlide = slideNum;
    setState((prevState) => ({
      ...prevState,
      currentOp:
        crewStandByGroupList.length > 0
          ? crewStandByGroupList[slideNum - 1]
          : "",
    }));
    currentSlideCrew3(slideNum);
  };

  //Función para agrupar los datos en función de los puntos de resumen
  const summaryLimopsDots = (crew) => {
    let summaryLimopsGroup = [];
    let summaryLimopsDot = [];
    crew.forEach((summaryLimopsItem) => {
      if (summaryLimopsDot.length > 3) {
        summaryLimopsGroup.push(summaryLimopsDot);
        summaryLimopsDot = [];
        summaryLimopsDot.push(summaryLimopsItem);
      } else {
        summaryLimopsDot.push(summaryLimopsItem);
      }
    });
    summaryLimopsGroup.push(summaryLimopsDot);
    return summaryLimopsGroup;
  };

  // Procesar y agrupar la lista de Crew Standby
  crewStandByList = state.crewStandbyList.reduce(
    (crewList, currentItem, index) => {
      const getDefaultValue = (value) => (value != null ? value : 0);

      const {
        mandoT24,
        cabinaT24,
        mandoPm,
        cabinaPm,
        mandoAm,
        cabinaAm,
        crewDistribution,
        cabinCrewDistribution,
      } = currentItem;

      crewList.push({
        ...currentItem,
        idx: index,
        t24Effective: getDefaultValue(mandoT24),
        t24RemainingFlight: getDefaultValue(cabinaT24),
        t24RemainingCabin: getDefaultValue(mandoT24),
        pmEffective: getDefaultValue(mandoPm),
        pmRemainingFlight: getDefaultValue(cabinaPm),
        pmRemainingCabin: getDefaultValue(mandoPm),
        amEffective: getDefaultValue(mandoAm),
        amRemainingFlight: getDefaultValue(cabinaAm),
        amRemainingCabin: getDefaultValue(mandoAm),
        crewDistribution: getDefaultValue(crewDistribution),
        cabinCrewDistribution: getDefaultValue(cabinCrewDistribution),
      });

      return crewList;
    },
    []
  );

  // Procesar y agrupar la lista de Crew Standby
  crewStandByGroupList = summaryLimopsDots(crewStandByList);

  return (
    <div className="crew-standby">
      {!isLoading && (
        <>
          <div className="critical-header">
            <GenericTitle
              title={"Crew Standby"}
              color={"#ff8a00"}
              lastUpdate={lastUpdate}
            />

            <div className="cf-counter-grp">
              <div className="cf-counter-grp-b">
                {/* <CrewStandbyCounter key={0} title={"LA"} count={3} />
                <CrewStandbyCounter key={0} title={"JJ"} count={1} /> */}
              </div>
            </div>
          </div>

          <div className="crew-standby-main">
            <div style={{ height: "100%", padding: ".5vh" }}>
              {!isBrasil ? (
                <>
                  <div className="summary-limops-tittle summary-limops-row-style header">
                    <div className="summary-limops-colx2 oh"></div>
                    <div className="summary-limops-colx2">AM</div>
                    <div className="summary-limops-colx2">PM</div>
                    <div className="summary-limops-colx2">ATO</div>
                    <div className="summary-limops-colx2">T24</div>
                  </div>

                  <div className="summary-limops-tittle summary-limops-row-style title">
                    <div className="summary-limops-colx2"></div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                  </div>

                  <div className="crew-slider">
                    {crewStandByGroupList.map((ctalFlightGrp, indx) => (
                      <CrewStandbyGroup
                        isBrasil={isBrasil}
                        key={indx}
                        {...{ ctalFlightGrp, indx }}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="crew-summary-limops-tittle summary-limops-row-style header brasil-header">
                    <div className="summary-limops-colx2 oh"></div>
                    {[
                      "00:00\n03:00",
                      "03:00\n06:00",
                      "06:00\n09:00",
                      "09:00\n12:00",
                      "12:00\n15:00",
                      "15:00\n18:00",
                      "18:00\n21:00",
                      "21:00\n00:00",
                    ].map((timeRange, index) => (
                      <div
                        key={index}
                        className="summary-limops-colx2 brasil-time"
                      >
                        {timeRange.split("\n").map((time, i) => (
                          <React.Fragment key={i}>
                            {time}
                            {i === 0 && <br />}
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div className="crew-summary-limops-tittle summary-limops-row-style title bra-brasil-title bg-transparent">
                    <div className="bra-summary-limops-colx2"></div>
                    {Array(8)
                      .fill()
                      .map((_, timeIndex) => (
                        <React.Fragment key={timeIndex}>
                          <div className="bra-summary-limops-sep"></div>
                          {["MANDO", "CABINA"].map((role, roleIndex) => (
                            <React.Fragment key={`${timeIndex}-${roleIndex}`}>
                              <div
                                className={`bra-summary-limops-col ${role.toLowerCase()}`}
                              >
                                {role}
                              </div>
                              {roleIndex === 0 && (
                                <div className="bra-summary-limops-sep"></div>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                  </div>

                  <div className="crew-slider brasil-slider">
                    {crewStandByGroupList.map((ctalFlightGrp, indx) => (
                      <CrewStandbyGroup
                        isBrasil={isBrasil}
                        key={indx}
                        {...{ ctalFlightGrp, indx }}
                      />
                    ))}
                  </div>

                  <div className="ato-casa">*ATO/CASA</div>
                </>
              )}
            </div>
            <div className="cf-carousell-dots">
              {crewStandByGroupList.map((ctalFlightGrp, indx) => (
                <span
                  className="dot3"
                  key={indx}
                  onClick={() => moveSlide(indx + 1)}
                ></span>
              ))}
            </div>
          </div>
        </>
      )}

      {isLoading && (
        <>
          {" "}
          <Loader id="ctrl-loader" />{" "}
        </>
      )}
    </div>
  );
}

export default CrewStandby;

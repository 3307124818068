import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import "moment-timezone";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Prompt } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { streamCrewStandby } from "../../../firebase";
import GenericSnackbar from "../../GenericSnackbar/GenericSnackbar";
import "./CrewStandbyConfig.css";
import CrewStandbyMaintainer from "./CrewStandbyMaintainer/CrewStandbyMaintainer";
import CrewStandbyMaintainerBrasil from "./CrewStandbyMaintainerBrasil/CrewStandbyMaintainerBrasil";

function CrewStandbyConfig({
  configurationData,
  configurationCheckbox,
  updateConfigurationData,
  isBrasil,
}) {
  const [isDirty, setIsDirty] = useState(false);
  const [state, setState] = useState(configurationData || {});
  const [snackbar, setSnackbar] = useState("");
  const [checked, setChecked] = useState(
    configurationCheckbox.crewStandbyConfig.orderView.checked
  );
  const [lastState] = useState(null);
  const [crewStandby, setCrewStandby] = useState({
    fltSummaryShift: { data: [] },
    fltSummaryShiftBra: { data: [] },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState(() => {
    const storedConfig = JSON.parse(localStorage.getItem("crewStandbyConfig"));
    return storedConfig?.selectedFilters || [];
  });

  // useMemo para calcular fleetList y fleetListBR
  const { fleetList, fleetListBR } = useMemo(() => {
    if (
      !crewStandby ||
      !crewStandby.fltSummaryShift ||
      !crewStandby.fltSummaryShiftBra
    ) {
      console.log("Data recibida en fltSummaryShift:", fleetList);
      return { fleetList: [], fleetListBR: [] };
    }

    // Construir lista de flota para SSC
    const fleetSSC = crewStandby.fltSummaryShift.data
      .map(
        (item) => `${item.subFleetCode || ""}${item.companyCode.split("/")[0]}`
      )
      .filter((value, index, self) => value && self.indexOf(value) === index);

    // Construir lista de flota para BR
    const fleetBR = crewStandby.fltSummaryShiftBra.data
      .map((item) => `${item.subFleetCode}${item.baseCode}`)
      .filter((value, index, self) => self.indexOf(value) === index);

    console.log("Fleet List SSC:", fleetSSC);
    console.log("Fleet List BR:", fleetBR);

    return { fleetList: fleetSSC, fleetListBR: fleetBR };
  }, [crewStandby]);

  useEffect(() => {
    console.log("Iniciando streamCrewStandby");
    setIsLoading(true);
    const unsubscribe = streamCrewStandby({
      next: (data) => {
        console.log("Datos recibidos en streamCrewStandby:", data);
        if (data && data.fltSummaryShift && data.fltSummaryShiftBra) {
          setCrewStandby({
            fltSummaryShift: { data: data.fltSummaryShift.data || [] },
            fltSummaryShiftBra: { data: data.fltSummaryShiftBra.data || [] },
          });
        } else {
          console.log("Estructura de datos inesperada:", data);
          setCrewStandby({
            fltSummaryShift: { data: [] },
            fltSummaryShiftBra: { data: [isBrasil] },
          });
        }
        setIsLoading(false);
      },
      error: (e) => {
        console.error("Error en streamCrewStandby:", e);
        setCrewStandby({
          fltSummaryShift: { data: [] },
          fltSummaryShiftBra: { data: [] },
        });
        setIsLoading(false);
      },
    });

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [isBrasil]);

  // Función para configurar el checkbox la primera vez
  const setCheckBoxFirstTime = useCallback(() => {
    if (window.localStorage.getItem("isFirstTime") == null) {
      const initialConfig = {
        orderView: {
          checked: "BRA",
        },
        selectedFilters: [],
      };

      updateConfigurationData("crewStandbyConfig", initialConfig).then(
        () => {
          setChecked("SSC");
        },
        (error) => {
          console.error(error);
        }
      );

      window.localStorage.setItem(
        "crewStandbyConfig",
        JSON.stringify(initialConfig)
      );
      window.localStorage.setItem("isFirstTime", false);
    }
  }, [updateConfigurationData]);

  useEffect(() => {
    setCheckBoxFirstTime();
  }, [setCheckBoxFirstTime]);

  // Manejar cambios en el radio button de selección
  const handleChange = (event) => {
    setChecked(event.target.value);
    setIsDirty(true);
    console.log("Checked value updated:", event.target.value);
  };

  // Manejar cambios en los filtros seleccionados
  const handleFiltersChange = (event, newValue) => {
    setSelectedFilters(newValue);
    setIsDirty(true);
    console.log("Filtros seleccionados:", newValue);
  };

  // Guardar cambios en la configuración
  const saveChanges = () => {
    const modDate = new Date();
    const newState = {
      ...state,
      lastModDate: modDate.toUTCString(),
      orderView: {
        checked: checked,
      },
      selectedFilters: selectedFilters,
    };

    // Guardar localmente
    localStorage.setItem("crewStandbyConfig", JSON.stringify(newState));

    // Guardar en el servidor
    updateConfigurationData("crewStandbyConfig", newState).then(
      () => {
        setSnackbar({
          msg: "¡Cambios guardados exitosamente!",
          date: new Date(),
          severity: "success",
        });
        setState(newState);
        setIsDirty(false);
      },
      (error) => {
        console.error(error);
        setSnackbar({
          msg: "Error al guardar los cambios. Inténtalo de nuevo más tarde",
          date: new Date(),
          severity: "error",
        });
      }
    );
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty]);

  return (
    <div className="ground-support-config">
      <div className="w-title">
        <span>Crew Standby</span>
      </div>
      <Tabs className="w-tab">
        <TabList>
          <Tab>Filter</Tab>
          <Tab>Group SSC</Tab>
          <Tab>Group BR</Tab>
        </TabList>

        <div className="contenedor">
          <TabPanel>
            <div className="desc">
              Select what you want to see on CrewStandby.
            </div>

            <div className="cf-filters block">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="SSC"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="SSC"
                    control={<Radio />}
                    label="SSC"
                    onChange={handleChange}
                    checked={checked === "SSC"}
                  />
                  <FormControlLabel
                    value="BRA"
                    control={<Radio />}
                    label="BRA"
                    onChange={handleChange}
                    checked={checked === "BRA"}
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="cf-filters">
              <div className="cf-autocomplete">
                <div className="ac-label">Select Filial</div>
                {isLoading ? (
                  <p>Cargando datos de la flota...</p>
                ) : (
                  <Autocomplete
                    multiple
                    freeSolo
                    limitTags={3}
                    id="multiple-limit-tags-fleets"
                    options={checked === "SSC" ? fleetList : fleetListBR}
                    value={selectedFilters}
                    onChange={handleFiltersChange}
                    classes={{ tag: "ac-tag" }}
                    disableCloseOnSelect={true}
                    renderOption={(option, { selected }) => (
                      <div className="option">
                        <Checkbox
                          color="primary"
                          classes={{ root: "ac-checkbox" }}
                          style={{ marginRight: "1vh" }}
                          checked={selected}
                        />
                        {option}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Fleet" />
                    )}
                  />
                )}
              </div>
            </div>

            <div className="cf-buttons">
              <div className="last-mod"></div>
              <Button
                variant="contained"
                classes={{ root: "vw-button" }}
                onClick={saveChanges}
                disableElevation
              >
                Apply changes to video wall
              </Button>
            </div>
            {snackbar ? (
              <GenericSnackbar
                key={snackbar.date}
                severity={snackbar.severity}
                message={snackbar.msg}
              />
            ) : null}
          </TabPanel>

          <TabPanel>
            <div className="w-filters">
              <CrewStandbyMaintainer
                crewStandby={crewStandby.fltSummaryShift.data}
                lastState={lastState}
              ></CrewStandbyMaintainer>
            </div>
          </TabPanel>

          <TabPanel>
            <div className="w-filters">
              <CrewStandbyMaintainerBrasil
                crewStandby={crewStandby.fltSummaryShiftBra.data}
                lastState={lastState}
              ></CrewStandbyMaintainerBrasil>
            </div>
          </TabPanel>
        </div>
      </Tabs>

      {snackbar ? (
        <GenericSnackbar
          key={snackbar.date}
          severity={snackbar.severity}
          message={snackbar.msg}
        />
      ) : null}
      <Prompt
        when={isDirty}
        message="You have unsaved changes. Are you sure you want to leave?"
      />
    </div>
  );
}

export default CrewStandbyConfig;

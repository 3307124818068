import "./SummaryLimopsGroup.css";
// import {SUMMARY_LIMOPS_OPER} from "../../../utils/Constants";
import React, { useEffect } from "react";
import { getSummaryLimopsGoals } from "../../../firebase";
// let summaryLimopsGoa = SUMMARY_LIMOPS_OPER;
function SummaryLimopsGroup(props) {
  // const [summaryLimopsGoals, setSummaryLimopsGoals] = useState(SUMMARY_LIMOPS_OPER)

  useEffect(() => {
    return getSummaryLimopsGoals({
      next: (querySnapshot) => {
        if (querySnapshot.data()) {
          // setSummaryLimopsGoals(querySnapshot.data().data);
          // summaryLimopsGoa = summaryLimopsGoals;
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
  });
  return (
    <div className="sl-mySlides sl-fade">
      <div className="summary-limops-main">
        <div className="summary-limops-cols">
          <div className="new-summary-limops-tittle new-summary-limops-row-style">
            <div className="summary-limops-colx2">OPER</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col-ac">A/C</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">ETP</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">APU</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">RNV</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">WXS</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">BRK</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">PAX</div>
            <div className="summary-limops-sep"></div>
            <div className="summary-limops-col">CGO</div>
          </div>

          {props.ctalFlightGrp.map((limops, indx) => {
            return (
              <div
                className="summary-limops-tittle summary-limops-row-style-tittle"
                key={indx}
              >
                <div className="summary-limops-oper-type">
                  {limops["operatorType"]}
                </div>
                <div className="summary-limops-sep-oper"></div>
                {/* <div */}
                {/* className='summary-limops-oper-ac'>{displayZero(limops["ac"]) + "/" + assemblerAircraft(props, limops)}</div> */}
                {/* <div className='summary-limops-sep-oper'></div> */}
                {/* <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "etp")} */}
                {/* value={limops["operatorType"].includes("NB") ? "-" : (displayZero(limops["etp"]))}></SummaryLimopsOper> */}
                {/* <div className='summary-limops-sep-oper'></div>
                            <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "apu")} value={displayZero(limops["apu"])}></SummaryLimopsOper>
                            <div className='summary-limops-sep-oper'></div>
                            <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "rnv")} value={displayZero(limops["rnv"])}></SummaryLimopsOper>
                            <div className='summary-limops-sep-oper'></div>
                            <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "wxs")}  value={displayZero(limops["wxs"])}></SummaryLimopsOper>
                            <div className='summary-limops-sep-oper'></div>
                            <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "brk")} value={displayZero(limops["brk"])}></SummaryLimopsOper>
                            <div className='summary-limops-sep-oper'></div>
                            <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "pax")} value={displayZero(limops["pax"])}></SummaryLimopsOper>
                            <div className='summary-limops-sep-oper'></div>
                            <SummaryLimopsOper getValue={getMinMax(limops["operatorType"], "cgo")}value={displayZero(limops["cgo"])}></SummaryLimopsOper> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// const getMinMax = (type, limops) => {
//     return summaryLimopsGoa[type][limops];
// }

// function displayZero(limops) {
//     return limops === 0 ? "-" : limops;
// }

// function assemblerAircraft(countsflt, limops) {
//     let number = 0;
//     if (countsflt.countAircraft.operatorList != null) {

//         countsflt.countAircraft.operatorList.forEach(s => {
//             if (s.group === limops['operatorType']) {
//                 number = s.count;
//             }
//         });
//     } else {
//         countsflt.countAircraft.subFlotaList.forEach(s => {
//             if (s.subtype === limops["operatorType"]) {
//                 number = s.count;
//             }
//         });
//     }

//     return number;
// }

export default SummaryLimopsGroup;

import React, { useEffect } from "react";
import "./VideoWall.css";
import ContingencyCard from "./components/ContingencyCard/ContingencyCard";
import CrewStandby from "./components/CrewStandby/CrewStandby";
import CriticalAircraft from "./components/CriticalAircraft/CriticalAircraft";
import CriticalFlights from "./components/CriticalFlights/CriticalFlights";
// import GroundSupport from "./components/GroundSupport/GroundSupport";
import LatamHeader from "./components/LatamHeader/LatamHeader";
import LatamNewsBand from "./components/LatamNewsBand/LatamNewsBand";
import Otp from "./components/Otp/Otp";
import PhoneDialer from "./components/PhoneDialer/PhoneDialer";
import SummaryLimops from "./components/SummaryLimops/SummaryLimops";
import Weather from "./components/Weather/Weather";
import {
  firestore,
  getDefaultConfiguration,
  streamUserConfiguration,
} from "./firebase";

function VideoWall(props) {
  const [configurationData, setConfigurationData] = React.useState();
  const userEmail = props.user?.email;
  const userCCO = props.userCCO;

  const updateConfigurationData = async (config, configData) => {
    configurationData[config] = configData;
    await firestore
      .collection("videowall-configuration")
      .doc(userEmail)
      .set(configurationData);
    setConfigurationData(configurationData);
    return true;
  };

  // It's CRITICAL to keep [] as the second argument to useEffect. Otherwise, we'll rack up UUUGE firestore bills due to opening a gazillion connections
  useEffect(() => {
    async function defaultConfig(cco) {
      const config = await getDefaultConfiguration(cco);
      return config;
    }
    const unsubscribe = streamUserConfiguration(userEmail, {
      next: (querySnapshot) => {
        if (querySnapshot.data()) {
          setConfigurationData(querySnapshot.data());
        } else {
          defaultConfig(userCCO).then((data) => {
            setConfigurationData(data ? data : {});
          });
        }
      },
      error: (e) => {
        console.error(e);
      },
    });
    return unsubscribe;
  }, [userEmail, userCCO]);
  return (
    <>
      {" "}
      {configurationData && (
        <div className="VideoWall">
          <LatamHeader />
          <LatamNewsBand userCCO={userCCO} />
          <ContingencyCard
            contingencyFlightsConfig={
              configurationData.contingencyFlightsConfig
            }
            dataFiltersConfig={configurationData.dataFiltersConfig}
          />
          <div className="temporal">
            <CriticalFlights
              criticalFlightsConfig={configurationData.criticalFlightsConfig}
              dataFiltersConfig={configurationData.dataFiltersConfig}
            />
            <SummaryLimops
              summaryLimopsConfig={configurationData.summaryLimopsConfig}
              dataFiltersConfig={configurationData.dataFiltersConfig}
            />
          </div>
          <CriticalAircraft
            criticalAircraftConfig={configurationData.criticalAircraftConfig}
            criticalAircraftCheckbox={configurationData}
          />
          <div className="layout">
            {/* <GroundSupport
              groundSupportConfig={configurationData.groundSupportConfig}
            /> */}
            <CrewStandby
              criticalFlightsConfig={configurationData.criticalFlightsConfig}
              dataFiltersConfig={configurationData.dataFiltersConfig}
              crewStandbyCheckbox={configurationData}
              updateConfigurationData={updateConfigurationData}
            />
            <Weather weatherConfig={configurationData.weatherConfig} />
          </div>
          <Otp otpConfig={configurationData.otpConfig} userCCO={userCCO} />
          {/** Phone Dialer Modal */} <PhoneDialer userRole={props.userRole} />
        </div>
      )}
    </>
  );
}

export default VideoWall;

import React from "react";
import "./CrewStandbyGroup.css";

function CrewStandbyGroup({ ctalFlightGrp, isBrasil }) {
  const allTimeRanges = [
    "00:00-03:00",
    "03:00-06:00",
    "06:00-09:00",
    "09:00-12:00",
    "12:00-15:00",
    "15:00-18:00",
    "18:00-21:00",
    "21:00-00:00",
  ];

  //Aqui se agrupan los datos para ambos flujos, subFleetCode y baseCode (Brasil), companyCode (SSC)
  const groupedData = ctalFlightGrp.reduce((acc, item) => {
    const key = `${item.subFleetCode}${item.baseCode}${item.companyCode}`;
    if (!acc[key]) {
      acc[key] = { ...item, ranges: {} };
    }
    acc[key].ranges[item.timeRange] = {
      MANDO: item.MANDO,
      CABINA: item.CABINA,
    };
    return acc;
  }, {});

  const getValueOrZeroBrasil = (value) => {
    if (typeof value === "string" && value.includes("/")) {
      const [effective, remaining] = value.split("/");
      // Mantenemos los valores originales, reemplazando '-' con '0'
      return `${effective === "-" ? "0" : effective}/${
        remaining === "-" ? "0" : remaining
      }`;
    } else if (typeof value === "number") {
      return value === 0 ? "0/0" : `${value}/0`;
    } else if (value === undefined || value === null || value === "") {
      return "-/-";
    } else {
      return `${value}/0`;
    }
  };

  // En el renderizado:
  return (
    <div className={"mySlides3 cf-fade crew"}>
      {Object.values(groupedData).map((ct) => {
        const combinedCode = `${ct.subFleetCode}${ct.baseCode || ""}${
          ct.companyCode ? ct.companyCode.substring(0, 2) : ""
        }`;
        // Renderizado para Brasil
        if (isBrasil) {
          return (
            <div
              className="summary-limops-tittle summary-limops-row-style"
              key={ct.idx}
            >
              <div className="summary-limops-colx2 isBrasil">
                {combinedCode}
              </div>
              {allTimeRanges.map((range, idx) => {
                const rangeData = ct.ranges[range] || {};
                const mando = getValueOrZeroBrasil(rangeData.MANDO);
                const cabina = getValueOrZeroBrasil(rangeData.CABINA);
                return (
                  <React.Fragment key={idx}>
                    <div
                      className={`summary-limops-col ${
                        mando === "-/-"
                          ? "black"
                          : mando === "0/0"
                          ? "red"
                          : "white"
                      }`}
                    >
                      {mando}
                    </div>
                    <div
                      className={`summary-limops-col ${
                        cabina === "-/-"
                          ? "black"
                          : cabina === "0/0"
                          ? "red"
                          : "white"
                      }`}
                    >
                      {cabina}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        } else {
          // Renderizado para SSC (no Brasil)
          return (
            <div
              className="summary-limops-tittle summary-limops-row-style"
              key={ct.idx}
            >
              <div className="summary-limops-colx2 ssc">{combinedCode}</div>
              <div
                className={
                  ct.mandoAm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.mandoAm || 0}
              </div>
              <div
                className={
                  ct.cabinaAm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.cabinaAm || 0}
              </div>
              <div
                className={
                  ct.mandoPm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.mandoPm || 0}
              </div>
              <div
                className={
                  ct.cabinaPm > 0
                    ? "summary-limops-col white"
                    : "summary-limops-col red"
                }
              >
                {ct.cabinaPm || 0}
              </div>
              <div
                className={
                  ct.cabinCrewDistribution > 0
                    ? "summary-limops-col ato white"
                    : "summary-limops-col ato red"
                }
              >
                {ct.cabinCrewDistribution || 0}
              </div>
              <div
                className={
                  ct.crewDistribution > 0
                    ? "summary-limops-col ato white"
                    : "summary-limops-col ato red"
                }
              >
                {ct.crewDistribution || 0}
              </div>
              <div
                className={
                  ct.mandoT24 > 0
                    ? "summary-limops-col white t24"
                    : "summary-limops-col red t24"
                }
              >
                {ct.mandoT24 || 0}
              </div>
              <div
                className={
                  ct.cabinaT24 > 0
                    ? "summary-limops-col white t24"
                    : "summary-limops-col red t24"
                }
              >
                {ct.cabinaT24 || 0}
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default CrewStandbyGroup;
